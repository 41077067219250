import { KeyboardArrowRight, KeyboardArrowLeft, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import dxDataGrid from "devextreme/ui/data_grid";

import "./DirectionButtons.scss";

interface ButtonsProps {
  gridInstance: dxDataGrid<any, any>;
  isHorizentalscrollDisable: boolean;
}

export const DirectionButtons = ({ gridInstance, isHorizentalscrollDisable }: ButtonsProps) => {
  const scrollGrid = (direction: string) => {
    if (!gridInstance) return;

    const scrollable = gridInstance.getScrollable();

    switch (direction) {
      case "up":
        scrollable.scrollTo({ top: 0 });
        break;
      case "down":
        scrollable.scrollTo({ top: scrollable.scrollHeight() });
        break;
      case "left":
        scrollable.scrollTo({ left: 0 });
        break;
      case "right":
        scrollable.scrollTo({ left: scrollable.scrollWidth() });
        break;
      default:
        break;
    }
  };

  return (
    <div className="scroll-control">
      <div className="round-button">
        <button onClick={() => scrollGrid("up")} className="scroll-button up">
          <KeyboardArrowUp />
        </button>
        <button onClick={() => scrollGrid("down")} className="scroll-button down">
          <KeyboardArrowDown />
        </button>
        <button onClick={() => scrollGrid("left")} className="scroll-button left" disabled={isHorizentalscrollDisable}>
          <KeyboardArrowLeft />
        </button>
        <button
          onClick={() => scrollGrid("right")}
          className="scroll-button right"
          disabled={isHorizentalscrollDisable}
        >
          <KeyboardArrowRight />
        </button>
      </div>
    </div>
  );
};
