import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Selection,
  Paging,
  SearchPanel,
  Toolbar,
  Item,
  LoadPanel,
} from "devextreme-react/data-grid";
import { dataGridConfig } from "../../../constants";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import { isEmpty, isNull } from "lodash";
import { CheckBox } from "devextreme-react/check-box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  MDMLegalEntityDTO,
  MDMSelectedLegalEntityDTO,
} from "../../../api-client-nswag/taxportal-client";
import { OidcIdentityContext } from "@rsmus/react-auth";
import CloseIcon from "@mui/icons-material/Close";
import { useRecoilState, useRecoilValue } from "recoil";
import { getAppName } from "../../../cui/commonComponents/AppNameToggle";

type DatagridRefType = DataGrid;

const SelectEditLegalEntities = (props: any) => {
  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
  const [unSelectedKeys, setunSelectedKeys] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<MDMSelectedLegalEntityDTO[]>(
    []
  );
  const [popup, setPopup] = useState(false);
  const [continueDisable, setContinueDisable] = useState(true);
  const { user } = useContext(OidcIdentityContext);
  const [syncClicked, setSyncClicked] = useState(false);
  const dataGridRef = useRef<DatagridRefType>(null);
  const [loaded, setLoaded] = useState(false);
  const appName = useRecoilValue(getAppName);

  useEffect(() => {
    if (props.selectedLegalEntityData) {
      const mappedData = props.selectedLegalEntityData.map((item: any) => ({
        mdmClientId: props.selectedRowsData[0]?.mdmClientId,
        clientId: item.clientId,
        clientName: item.clientName,
        hqClientName: props.selectedRowsData[0]?.name,
        hqSiteId: props.selectedRowsData[0].hqSiteId,
        suppressFolderCreation: false,
        suppressDropdown: false,
        suppressProjectStatus: false,
        userName: user?.profile.preferred_username as string,
      }));
      props.setDataPermission(mappedData);
    }
  }, [props.selectedLegalEntityData]);
  useEffect(() => {
    if (props.legalData.gridLegalData?.length === 0) {
      const fetchData = async () => {
        try {
          props.setLegalData({
            pending: true,
            fulfilled: false,
            gridLegalData: [],
          });
          const data = await taxPortalClientApi.mDM_GetMDMLegalEntitiesPerSite(
            props.selectedRowsData[0]?.mdmClientId,
            props.selectedRowsData[0]?.hqSiteId
          );
          props.setLegalData({
            pending: false,
            fulfilled: true,
            gridLegalData: data.legalEntites,
          });
        } catch (error) {
          console.log(error, "error");
        }
      };
      fetchData();
      props.setSelectedLegalEntityData([]);
    }
  }, []);
  const onSelectionchanged = (e: any) => {
    if (e.currentDeselectedRowKeys.length > 0) {
      setPopup(true);
    }
    props.setSelectedLegalEntityData(e.selectedRowKeys);
    setSelectedKeys(e.selectedRowKeys);
    setunSelectedKeys(e.currentDeselectedRowKeys);
    setContinueDisable(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const selectedDatas =
          await taxPortalClientApi.mDM_GetSelectedLegalEntities(
            props.selectedRowsData[0].hqSiteId,
            props.selectedRowsData[0].mdmClientId
          );
        const uniqueData = selectedDatas.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.clientId === item.clientId)
        );
        setSelectedData(uniqueData);
      } catch (e) {
        console.log(e, "error");
      }
    };
    fetchData();
  }, []);

  const handleClose = () => {
    setSelectedKeys((prevData) => [...prevData, ...unSelectedKeys]);
    setPopup(false);
  };

  const handleYes = () => {
    setPopup(false);
  };

  const text =
    "You are removing entities that are associated with the site. This will lead to deletion of associated Project Status records with this entity, removal of corresponding Entity, Project, Phase choices options, and hiding related folders from non-admin roles. If there existing IR record for selected entities - no actions will be made for the IR iSheet.\nAre you sure you want to continue? ";

  useEffect(() => {
    continueDisable === true
      ? props.setBtnContinueDisable(true)
      : props.setBtnContinueDisable(false);
  }, [props.selectionMode, continueDisable]);

  const refreshButtonOptions = {
    icon: "refresh",
    onClick: async () => {
      localStorage.setItem("Legalentities", "");
      const data = await taxPortalClientApi.mDM_GetMDMLegalEntitiesPerSite(
        props.selectedRowsData[0].mdmClientId,
        props.selectedRowsData[0].hqSiteId
      );
      props.setLegalData({
        pending: false,
        fulfilled: true,
        gridLegalData: data.legalEntites,
      });
    },
  };
  useEffect(() => {
    const selectedRowKeys = props.legalData.gridLegalData
      .filter((row: { clientId: any }) =>
        selectedData.some(
          (selectedRow) => selectedRow.clientId === row.clientId
        )
      )
      .map((row: { clientId: any }) => row);
    setSelectedKeys(selectedRowKeys);
    setLoaded(true);
  }, [props.legalData.gridLegalData, selectedData]);
  console.log(props.handleEdit);
  return (
    <div>
      <div
        style={{
          margin: "0 0 10px 0",
          display: "flex",
          justifyContent: "space-between",
        }}
      ></div>
      <Dialog
        open={popup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{ marginTop: "30px" }}>
          <DialogContent>
            <Typography style={{ whiteSpace: "pre-line" }}>{text}</Typography>
          </DialogContent>
        </div>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ margin: "0 16px 0 0" }}
            variant="outlined"
          >
            No
          </Button>
          <Button
            onClick={handleYes}
            style={{ margin: "0 16px 0 0" }}
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {loaded === true ? (
        <DataGrid
          ref={dataGridRef}
          {...dataGridConfig}
          showBorders={true}
          height={300}
          selectedRowKeys={selectedKeys}
          onSelectionChanged={onSelectionchanged}
          dataSource={props.legalData.gridLegalData}
          noDataText={
            props.legalData.pending === true
              ? "Loading..."
              : syncClicked
              ? "Refresh after sometime"
              : "Sync Legal Entity"
          }
          onEditorPreparing={(e) => {
            if (e.parentType === "searchPanel") {
              e.editorOptions.onValueChanged = (args: any) => {
                const searchValue = args.value?.toLowerCase() || "";
                const grid = e.component;
                grid.filter([
                  ["clientId", "contains", searchValue],
                  "or",
                  ["clientName", "contains", searchValue],
                ]);
              };
            }
          }}
        >
          {props.handleEdit === true ? (
            <Selection mode={props.selectionMode} />
          ) : (
            false
          )}
          <Paging defaultPageSize={5} />
          <LoadPanel enabled={false} />
          <Paging defaultPageSize={5} />
          <Scrolling columnRenderingMode="virtual" />
          <SearchPanel placeholder={"Global Search..."} visible />
          
          <Toolbar>
            <Item location={"before"}>{props.selectedRowsData?.name}</Item>
            <Item name="alignRight">
              <div>
                <Button
                  style={{ margin: "0 12px 0 0 " }}
                  onClick={async () => {
                    await taxPortalClientApi.mDM_SyncLegalEntitiesConsent(
                      props.selectedRowsData[0]?.mdmClientId,
                      null,
                      props.selectedRowsData[0]?.hqSiteId
                    );
                    setSyncClicked(true);
                  }}
                  variant="outlined"
                >
                  Sync Legal Entites
                </Button>
              </div>
            </Item>
            <Item name="searchPanel" location="after" />
            <Item
              widget="dxButton"
              options={refreshButtonOptions}
              name="alignRight"
            />
          </Toolbar>
          <Column
            alignment="left"
            dataField="clientId"
            caption={"Client Id"}
          ></Column>
          <Column
            alignment="left"
            dataField="clientName"
            caption={"Client Name"}
          ></Column>
        </DataGrid>
      ) : null}
    </div>
  );
};

export default SelectEditLegalEntities;
