import { useEffect, useRef, useState } from "react";
import "devextreme/data/odata/store";
import {
  Column,
  DataGrid,
  FilterRow,
  HeaderFilter,
  Scrolling,
  Selection,
  SearchPanel,
  Toolbar,
  Item,
  Paging,
} from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { calculateGridHeight, executeDownload } from "../../../util/Utils";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";

import { SnackbarMessage, useSnackbar } from "../../../hooks";
import { defaultPageSize } from "../../../constants";
import { CellRenderDate } from "../../../util/cellRendersAtoms";
import { SitesGridProps, constants, selectedOptionType } from "./UpgradeSitesGrids.types";

import "./UpgradeSites.scss";
import { ModalSiteChangesDetail } from "./ModalSiteChangesDetail";
import { useRecoilValue } from "recoil";
import { getAppName } from "../../commonComponents/UpgradeSitesToggle";

type DatagridRefType = DataGrid;

const { BUTTON_REVIEW_CHANGES, COLUMNS, BUTTON_DOWNLOAD_EXCEL, FILE_SITE_UPGRADE_NAME, ZIP_FILE_EXTENSION } = constants;
const {
  HQ_SITE_ID,
  ORG_ID,
  MDM_MASTER_CLIENT_ID,
  MDM_CLIENT_ID,
  NAME,
  TEMPLATE_NAME_OLD,
  TEMPLATE_NAME_NEW,
  UPGRADE_DATE,
  UPGRADED_BY,
  STATUS,
} = COLUMNS;

export const SitesGrid = ({
  sitesData,
  selectedSites,
  setSelectedSites,
  setSelectedSitesData,
  dropDown,
  setDropDown,
  fetchData,
  toggleSitesGrids,
  setPageNumber,
  setSearchTerm,
}: SitesGridProps) => {
  const dataGridRef = useRef<DatagridRefType>(null);
  const { showSnackbar } = useSnackbar();
  const [dropDownOptions, setDropDownOptions] = useState<selectedOptionType[]>([]);
  const count = Math.ceil(sitesData.totalCount / defaultPageSize);
  const areDropAndSiteSelected = !dropDown || !selectedSites.length;
  const [isDisplayModal, setIsDisplayModal] = useState(false);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const [idUpgradeSiteTracks, setIdUpgradeSiteTracks] = useState<number>();
  const appName = useRecoilValue(getAppName);
  const getDropDownOptions = async () => {
    try {
      const dropDownOpt = await taxPortalClientApi.highQ_GetHQSiteTemplate(appName);
      const dropOptions = dropDownOpt.map(({ id, sitename }) => ({
        value: id,
        label: sitename,
      }));
      setDropDownOptions(dropOptions);
    } catch (error) {}
  };

  const onSelectionchanged = ({ selectedRowKeys, selectedRowsData }: SelectionChangedEvent) => {
    setSelectedSites(selectedRowKeys);
    const validStatuses = selectedRowsData.filter((site) => site.status !== null);
    setIsDownloadEnabled(() => (selectedRowKeys.length && validStatuses.length ? true : false));
  };

  const getSitesToDownload = () => {
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    return selectedData.filter((site) => site.status !== null).map((siteData) => siteData.idUpgradeSiteTracks);
  };

  const refreshButtonOptions = {
    icon: "refresh",
    onClick: () => {
      fetchData();
    },
  };

  const handleSeeDetailsButton = (idUpgradeSiteTracks: number) => {
    setIdUpgradeSiteTracks(idUpgradeSiteTracks);
    setIsDisplayModal(true);
  };

  const handleClose = () => {
    setIsDisplayModal(false);
  };

  const handleInitiateButton = () => {
    const selectedData = dataGridRef.current!.instance.getSelectedRowsData();
    const selected = dataGridRef.current!.instance.getSelectedRowKeys();
    setSelectedSitesData(selectedData);
    setSelectedSites(selected);
    toggleSitesGrids(false);
  };

  const handleDropDownChange = (e: SelectChangeEvent) => {
    setDropDown(e.target.value);
  };

  const onChangePagination = (e: any, pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  const renderDetails = ({ row: { data } }: any) => {
    return data.status ? (
      <IconButton
        className="dx-datagrid dx-link"
        aria-label="See Details"
        title="See Details"
        onClick={() => handleSeeDetailsButton(data.idUpgradeSiteTracks)}
      >
        <SearchIcon />
      </IconButton>
    ) : null;
  };

  const handleExportButton = async () => {
    showSnackbar({ message: SnackbarMessage.ZIP_UPGRADE_DOWNLOAD_MESSAGE });
    try {
      const sitesToDownload = getSitesToDownload();
      const base64String = await taxPortalClientApi.HQTemplateSiteUpgradeResultExcelExport(sitesToDownload);
      if (base64String) executeDownload(base64String, "", FILE_SITE_UPGRADE_NAME, ZIP_FILE_EXTENSION);
    } catch (error) {
      showSnackbar({ message: SnackbarMessage.ZIP_DOWNLOAD_FAILED, severity: "error" });
    }
  };

  useEffect(() => {
    getDropDownOptions();
  }, [appName]);

  return (
    <div style={{ marginRight: "20px" }}>
      <div className="counter">
        <h4>
          {selectedSites.length} out of {sitesData.totalCount} records selected
        </h4>
      </div>

      <DataGrid
        id="grid-sites"
        ref={dataGridRef}
        keyExpr="hqSiteId"
        allowColumnResizing
        dataSource={sitesData.sites}
        showBorders
        onSelectionChanged={onSelectionchanged}
        height={calculateGridHeight(260)}
        remoteOperations
        noDataText={sitesData.pending ? "Loading..." : "No Data"}
        selectedRowKeys={selectedSites}
      >
        <Selection mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
        <Scrolling columnRenderingMode="virtual" />
        <FilterRow visible />
        <HeaderFilter visible />
        <SearchPanel
          placeholder={"Global Search..."}
          onTextChange={(e) => {
            setSearchTerm(e);
          }}
          visible
        />
        <Paging enabled={false} />
        <Toolbar>
          <Item location="after">
            <Button
              variant="outlined"
              disabled={!isDownloadEnabled}
              onClick={() => {
                handleExportButton();
              }}
            >
              {BUTTON_DOWNLOAD_EXCEL}
            </Button>
          </Item>
          <Item location="after">
            <FormControl size="medium" variant="outlined" sx={{ width: 300 }}>
              <FormLabel className="form-label">Select a Template</FormLabel>
              <Select
                style={{ height: 36, fontSize: "14px" }}
                defaultValue={dropDown}
                onChange={handleDropDownChange}
                MenuProps={{
                  style: {
                    maxHeight: 300,
                  },
                }}
              >
                {dropDownOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value} style={{ fontSize: "14px" }}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Item>
          <Item location="after">
            <Button
              variant="outlined"
              disabled={areDropAndSiteSelected}
              onClick={() => {
                handleInitiateButton();
              }}
            >
              {BUTTON_REVIEW_CHANGES}
            </Button>
          </Item>
          <Item name="searchPanel" location="after" />
          <Item widget="dxButton" options={refreshButtonOptions} name="alignRight" />
        </Toolbar>
        <Column dataField="hqSiteId" caption={HQ_SITE_ID} minWidth={70} alignment="left" />
        <Column dataField="orgId" caption={ORG_ID} minWidth={135} alignment="left" />
        <Column dataField="mdmMasterClientId" caption={MDM_MASTER_CLIENT_ID} minWidth={135} alignment="left" />
        <Column dataField="mdmClientId" caption={MDM_CLIENT_ID} minWidth={100} alignment="left" />
        <Column minWidth={300} dataField="name" caption={NAME} alignment="left" />
        <Column minWidth={300} dataField="templateNameOld" caption={TEMPLATE_NAME_OLD} alignment="left" />
        <Column minWidth={300} dataField="templateNameNew" caption={TEMPLATE_NAME_NEW} alignment="left" />
        <Column
          cellRender={({ data }) => CellRenderDate(data.upgradeDate)}
          dataField="upgradeDate"
          caption={UPGRADE_DATE}
          minWidth={180}
          alignment="left"
        />
        <Column dataField="upgradeBy" caption={UPGRADED_BY} minWidth={180} alignment="left" />
        <Column dataField="status" caption={STATUS} minWidth={180} alignment="left" />
        <Column cellRender={renderDetails} type="buttons" caption="Changes detail" />
      </DataGrid>
      <div className="grid-pagination">
        <Pagination onChange={onChangePagination} count={count} variant="outlined" shape="rounded" />
      </div>
      <ModalSiteChangesDetail
        handleClose={handleClose}
        isDisplayModal={isDisplayModal}
        idUpgradeSiteTracks={idUpgradeSiteTracks}
      />
    </div>
  );
};
