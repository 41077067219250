import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import {
  getTaxYearResponse,
} from "../SitesWithLegalEntities";
import { format } from "date-fns";

import DateBox from "devextreme-react/date-box";

export const storeStartValueForCS = atom({
  key: "storeStartValueForCS",
  default: null,
});

export const storeEndValueForCS = atom({
  key: "storeEndValueForCS",
  default: null,
});

const dateLabel = { "aria-label": "Date" };

function SelectDates(props: any) {
  const [startValue, setStartValue] = useState<any>();
  const [endValue, setEndValue] = useState<any>();
  const [taxYear, setTaxYear] = useRecoilState<any>(getTaxYearResponse);
  const [storeStartYear, setstoreStartYear] = useRecoilState<any>(storeStartValueForCS);
  const [storeEndYear, setstoreEndYear] = useRecoilState<any>(storeEndValueForCS);


  useEffect(() => {
    if (endValue !== undefined && endValue !== null) {
      const formattedYear = format(new Date(endValue), 'yyyy');
      setTaxYear(formattedYear);
      setstoreStartYear(startValue);
      setstoreEndYear(endValue);
      props.setBtnContinueDisable(false);
    }
  }, [endValue]);

  useEffect(() => {
    if(taxYear === null) props.setBtnContinueDisable(true);
  },[taxYear])
  
  useEffect(() => {
    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return format(date, "yyyy-MM-dd");
    };

    const formattedStartDate = (startValue !== undefined) ? formatDate(startValue) : null;
    const formattedEndDate = (endValue !== undefined) ? formatDate(endValue) : null;
    props.setSiteDates({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  }, [startValue, endValue]);

  const currentYear = new Date().getFullYear();
  const minDate = new Date(currentYear - 10, 0, 1); 
  const maxDate = new Date(currentYear + 15, 11, 31); 
  return (
    <>
      <div>Instruction:</div>
      <div>
        {
          "Select the start and end dates for your client. Refer to site creation form for these details or reach out to Engagement team."
        }
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          margin: "16px 0 0 0",
        }}
      >
 
        <label style={{ display: "flex", alignItems: "center" }}>
          Start Date
        </label>

        <DateBox
          onValueChanged={(newValue) => setStartValue(newValue.value)}
          defaultValue={taxYear === null ?null:storeStartYear}
          inputAttr={dateLabel}
          type="date"
          min={minDate}
          max={maxDate}
        />
        <label style={{ display: "flex", alignItems: "center" }}>
          End Date
        </label>
        <DateBox
          onValueChanged={(newValue) => setEndValue(newValue.value)}
          defaultValue={taxYear === null ?null:storeEndYear}
          inputAttr={dateLabel}
          type="date" 
          min={startValue}
          max={maxDate}
        />
      </div>
    </>
  );
}

export default SelectDates;
