import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import dxTreeView, { ItemClickEvent } from "devextreme/ui/tree_view";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
// import { useGetURL, useSnackbar } from "../../hooks";
import { useSnackbar } from "../../hooks";
import { refreshFlagAtom } from "../../constants";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import { Descriptions } from "../../api-client-nswag/taxportal-client";
import { IManageFilesDTO, IManageFilesState, IManageFilesVars } from "./IManageFilesTypes";

import "./IManageFiles.scss";
import IManageFiles from "./IManageFiles";

const { IMANAGE_FILES, TAXPORTAL_FOLDERS } = Descriptions;
const initialStateIManageFiles = { pending: false, fulfilled: false, data: [] };

export default function IManageFilesContainer() {
  //const siteID = useGetURL();
  const refreshFlag = useRecoilValue(refreshFlagAtom);
  const { showSnackbar } = useSnackbar();
  const [filesToMove, setFilesToMove] = useState<IManageFilesState>(initialStateIManageFiles);
  const [foldersToShow, setFoldersToShow] = useState<IManageFilesState>(initialStateIManageFiles);
  const siteID = sessionStorage.getItem("siteId") as string;

  const fetchTreeItems = async (type: Descriptions, setData: Function) => {
    setData({ ...initialStateIManageFiles, pending: true });
    if (siteID) {
      try {
        const newData = await taxPortalClientApi.taxPortal_GetDirectories(type, parseInt(siteID));
        setData({ ...initialStateIManageFiles, fulfilled: true, data: newData.files });
      } catch (error) {
        showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
        setData(initialStateIManageFiles);
      }
    } else {
      setData(initialStateIManageFiles);
    }
  };

  const findIdx = (arr: any, id: any) => {
    return arr.findIndex((object: any) => {
      return object.id === id;
    });
  };

  const getChildren = (level: number, node: any, data: IManageFilesDTO[], newData: IManageFilesDTO[]) => {
    const selected = node.itemData ? { ...node.itemData } : null;

    let nodes = [selected];
    let nestedNodes = "parent";
    for (let i = level; i > 1; i--) {
      const prnt = nestedNodes.split(".").reduce((prev, cur) => prev[cur], node);
      nodes.push({ ...prnt.itemData });
      nestedNodes += ".parent";
    }

    let pushData = newData;
    const numberToTake =
      newData[0] && newData[0].showMoreLimit ? newData[0].showMoreLimit : IManageFilesVars.SHOW_MORE_MIN_LIMIT;
    if (level === 2 && newData.length > numberToTake) {
      pushData = showMoreData(newData, numberToTake!);
    }

    nodes[0].items = pushData;
    nodes.forEach((obj, ind) => {
      if (nodes[ind + 1]) {
        const idx = findIdx(nodes[ind + 1].items, obj.id);
        nodes[ind + 1].items[idx] = obj;
      } else {
        const idx = findIdx(data, obj.id);
        data[idx] = obj;
      }
      nodes.shift();
    });

    return data as IManageFilesDTO[];
  };

  const showMoreData = (data: IManageFilesDTO[], take: number) => {
    const firstData = data.slice(0, take);
    const newData = [
      ...firstData,
      {
        text: "Show More",
        id: `show-more-${data[0].id}`,
        hasItems: true,
        itemType: "folder",
        items: data.slice(take, data.length),
      },
    ];
    return newData;
  };

  const getIManageData = async (treeView: any, level: number, node?: any, clientId?: string, workspaceId?: string) => {
    if (siteID) {
      try {
        const currData = filesToMove.data;
        let newData = await taxPortalClientApi.taxPortal_GetIManageFiles(parseInt(siteID), clientId, workspaceId);
        const message = SnackbarMessage.IMANAGE_FILES_NO_DATA.replace("%item%", node.itemData.text) as SnackbarMessage;
        if (!newData.length) showSnackbar({ message: message, severity: "info" });
        newData = getChildren(level, node, currData, newData) as IManageFilesDTO[];
        treeView.option("dataSource", newData);
        setFilesToMove({ ...initialStateIManageFiles, fulfilled: true, data: newData });
      } catch (error) {
        showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
        setFilesToMove(initialStateIManageFiles);
      }
    }
  };

  const settingLoadingLabel = (element: HTMLElement) => {
    let emptyNode: HTMLElement | null = element.querySelector("ul > li ")!.querySelector(".dx-item-content > span");
    if (emptyNode!.innerText === "") emptyNode!.innerText = "Loading...";
  };

  const showMoreLabel = (element: ItemClickEvent<any>) => {
    const elmnt = element.itemElement?.querySelector("span");
    if (elmnt!.innerText === "Show More") {
      elmnt!.innerHTML = "<b>Hide</b>";
    } else if (elmnt!.innerText === "Hide") {
      elmnt!.innerHTML = "<b>Show More</b>";
    }
  };

  const onItemExpanded = (e: ItemClickEvent<any>) => {
    showMoreLabel(e);
    const item = e.itemData;
    const level = parseInt(item!.level);
    const treeViewEl = e.element;
    const treeView = dxTreeView.getInstance(treeViewEl);

    if (item!.items![0].text === "" && item!.items![0].disabled === true) {
      if (e.itemElement!.parentElement) settingLoadingLabel(e.itemElement!.parentElement);
      let clientId = item!.id as string,
        workspaceId = "";
      if (level >= 3) {
        clientId = item!.parentId as string;
        workspaceId = item!.id as string;
      }
      getIManageData(treeView, level, e.node, clientId, workspaceId);
    }
  };

  useEffect(() => {
    fetchTreeItems(IMANAGE_FILES, setFilesToMove);
    fetchTreeItems(TAXPORTAL_FOLDERS, setFoldersToShow);
  }, [siteID, refreshFlag]);

  return (
    <IManageFiles
      onItemExpanded={onItemExpanded}
      showMoreLabel={showMoreLabel}
      filesToMove={filesToMove}
      foldersToShow={foldersToShow}
      siteID={parseInt(siteID)}
    />
  );
}
