import { useEffect, useRef, useState } from "react";
import { DataGrid, Column, Paging, Button, Editing, Toolbar, Item, Pager } from "devextreme-react/data-grid";
import { Button as DxButton } from "devextreme-react/button";

import TaskAlt from "@mui/icons-material/TaskAlt";

import { GetUserRolloverDTO } from "../../../api-client-nswag/taxportal-client";

import { DirectionButtons } from "../DirectionButton/DirectionButtons";

import { RolloverResponse } from "../../../pages/Rollovers/Rollovers.types";
import { PAGE_CAPTIONS, ROLLOVER_OPTIONS, ROLLOVER_STATUSES } from "../../../pages/Rollovers/Rollovers.constants";
import { dataGridConfig } from "../../../constants";
import { becomeUpperCase, calculateGridHeight, convertFormatDate, toCapitalCase } from "../../../util/Utils";

import "./GridRollover.scss";

type DatagridRefType = DataGrid;

const {
  GRID_TITLE,
  USER,
  TYPE,
  ROLOVER_REQUESTED_DATE,
  ROLOVER_SCHEDULED_DATE,
  ROLLOVER_DATE,
  STATUS,
  STATUS_DETAILS,
  EXECUTION_DETAILS,
  FILES,
  RETRY,
  CANCEL_SCHEDULED,
  CANCELED_BY,
  CANCELED_DATE,
  NOTIFICATION_SENT,
  FIRST_NOTIFICATION_SENT,
  SECOND_NOTIFICATION_SENT,
} = PAGE_CAPTIONS;

const { SUCCESS, ERROR } = ROLLOVER_STATUSES;

interface GridProps {
  data: RolloverResponse;
  handleOpenModal: (e: any) => void;
  handleOpenStatusDetailsModal: (e: any) => void;
  refreshFunction: () => Promise<void>;
  requestRollover: (selected?: string, processTrackId?: number) => void;
  cancelRollover: (rolloverId: number) => void;
}

export default function GridRollover({
  data,
  handleOpenModal,
  handleOpenStatusDetailsModal,
  refreshFunction,
  requestRollover,
  cancelRollover,
}: GridProps) {
  const rolloversGridRef = useRef<DatagridRefType>(null);
  const [gridHeight, setGridHeight] = useState(calculateGridHeight(140));
  const [totalWidth, setTotalWidth] = useState(0);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const cellRenderForDate = (e: string) => {
    return <>{convertFormatDate(e)}</>;
  };

  const cellRenderByFieldType = (date: string, status: string) => {
    const dataField = status === SUCCESS || status === ERROR ? date : "";
    return dataField ? <>{convertFormatDate(dataField)}</> : null;
  };

  const refreshButtonOptions = {
    icon: "refresh",
    onClick: refreshFunction,
  };

  const cellUpperCase = (e: string) => e && <>{becomeUpperCase(e)}</>;
  const cellCapitalCase = (e: string) => <>{toCapitalCase(e)}</>;
  const renderCheckIcon = (notification_one: boolean, notification_two: boolean) => {
    return (
      <div className="hq-icon-button">
        {notification_one && <TaskAlt titleAccess={FIRST_NOTIFICATION_SENT} />}
        {notification_two && <TaskAlt titleAccess={SECOND_NOTIFICATION_SENT} />}
      </div>
    );
  };

  const renderCancelButton = (value: boolean, id: number, data: GetUserRolloverDTO) => {
    return value && <DxButton onClick={() => cancelRollover(id)} icon="clear" className="hq-icon-button" />;
  };

  const renderRetryButton = (value: boolean, data: GetUserRolloverDTO) => {
    const { rollOverType, id } = data;
    return (
      value && (
        <DxButton
          onClick={() => requestRollover(ROLLOVER_OPTIONS[rollOverType as keyof typeof ROLLOVER_OPTIONS], id)}
          icon="refresh"
          className="hq-icon-button"
        />
      )
    );
  };
  const calculateTotalColumnWidth = () => {
    if (rolloversGridRef.current) {
      const gridInstance = rolloversGridRef.current.instance;
      if (gridInstance) {
        const columnCount = gridInstance.columnCount();
        let totalWidth = 0;
        for (let i = 0; i < columnCount; i++) {
          const colWidth = gridInstance.columnOption(i, "width");
          totalWidth += colWidth || dataGridConfig.columnMinWidth || 100;
        }

        setTotalWidth(totalWidth);
        const screenWidth = window.innerWidth;
        setIsOverflowing(() => {
          return totalWidth < screenWidth;
        });
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      calculateTotalColumnWidth();
      const screenHeight = window.innerHeight;
      if (screenHeight > 730) {
        setGridHeight(calculateGridHeight(150));
      } else {
        setGridHeight(calculateGridHeight(140));
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="hq-page__grid app-container">
      <DataGrid
        {...dataGridConfig}
        ref={rolloversGridRef}
        allowColumnResizing={true}
        height={gridHeight}
        showBorders={false}
        showRowLines={true}
        showColumnLines={false}
        dataSource={data.rollovers}
        columnMinWidth={60}
        wordWrapEnabled={true}
        noDataText={data.pending ? "Loading..." : "No data"}
      >
        <Paging enabled={true} defaultPageSize={10} />
        <Pager visible={true} displayMode="full" />
        <Editing mode="row" allowUpdating useIcons />
        <Toolbar>
          <Item location="before">
            <h2 className="hq-title">{GRID_TITLE}</h2>
          </Item>
          <Item widget="dxButton" options={refreshButtonOptions} />
        </Toolbar>
        <Column
          alignment="left"
          dataField="createdBy"
          caption={USER}
          cellRender={(e) => (e.data.createdBy !== null ? e.data.createdBy.toLowerCase() : e.data.createdBy)}
        />
        <Column
          alignment="left"
          dataField="rollOverType"
          caption={TYPE}
          width={60}
          cellRender={(e) => cellCapitalCase(e.data.rollOverType)}
          allowEditing={false}
        />
        <Column
          dataField="rolloverRequestedDate"
          caption={ROLOVER_REQUESTED_DATE}
          width={100}
          cellRender={(e) => cellRenderForDate(e.data.rolloverRequestedDate)}
          allowEditing={false}
        />
        <Column
          dataField="scheduledDate"
          caption={ROLOVER_SCHEDULED_DATE}
          width={100}
          cellRender={(e) => cellRenderForDate(e.data.scheduledDate)}
          allowEditing={false}
        />
        <Column
          dataField="lastModified"
          caption={ROLLOVER_DATE}
          width={100}
          cellRender={(e) => cellRenderByFieldType(e.data.lastModified, e.data.rollOverStatus)}
          allowEditing={false}
        />
        <Column
          dataField="rollOverStatus"
          caption={STATUS}
          width={80}
          cellRender={(e) => cellCapitalCase(e.data.rollOverStatus)}
          allowEditing={false}
        />
        <Column
          dataField="rollOverStatusMessage"
          caption={STATUS_DETAILS}
          cellRender={(e) => cellUpperCase(e.data.rollOverStatusMessage)}
          allowEditing={false}
        />
        <Column type="buttons" caption={EXECUTION_DETAILS} width={80}>
          <Button onClick={handleOpenStatusDetailsModal} icon="file" name={EXECUTION_DETAILS} />
        </Column>
        <Column type="buttons" caption={FILES} width={60}>
          <Button onClick={handleOpenModal} icon="file" name={FILES} />
        </Column>
        <Column
          type="buttons"
          caption={RETRY}
          width={60}
          cellRender={(e) => renderRetryButton(e.data.retryRollOver, e.data)}
        />
        <Column
          type="buttons"
          caption={CANCEL_SCHEDULED}
          width={80}
          cellRender={(e) => renderCancelButton(e.data.cancelRollOver, e.data.id, e.data)}
        />
        <Column
          alignment="left"
          dataField="canceledBy"
          caption={CANCELED_BY}
          cellRender={(e) => (e.data.canceledBy !== null ? e.data.canceledBy.toLowerCase() : e.data.canceledBy)}
          allowEditing={false}
        />
        <Column
          dataField="canceledDate"
          caption={CANCELED_DATE}
          width={100}
          cellRender={(e) => cellRenderForDate(e.data.canceledDate)}
          allowEditing={false}
        />
        <Column
          type="buttons"
          cellRender={(e) => renderCheckIcon(e.data.firstNotificationSent, e.data.secondNotificationSent)}
          caption={NOTIFICATION_SENT}
          width={80}
        />
        {rolloversGridRef.current ? (
          <DirectionButtons
            gridInstance={rolloversGridRef.current.instance}
            isHorizentalscrollDisable={isOverflowing}
          />
        ) : null}
      </DataGrid>
    </div>
  );
}
