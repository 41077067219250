import { useContext, useEffect, useState } from "react";
import { dataGridConfig } from "../../../constants";
import DataGrid, {
  Column,
  Scrolling,
  LoadPanel,
  Paging,
} from "devextreme-react/data-grid";
import { CheckBox } from "devextreme-react/check-box";
import { OidcIdentityContext } from "@rsmus/react-auth";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import { MDMSelectedLegalEntityDTO } from "../../../api-client-nswag/taxportal-client";

export interface selectedDataResponse {
  pending: boolean;
  data: MDMSelectedLegalEntityDTO[];
}

const LegalEntitesEditPermission = (props: any) => {
  const { user } = useContext(OidcIdentityContext);
  const [arrData, setArrData] = useState<selectedDataResponse>({pending: true, data:[]});

  useEffect(() => {
    const fetchData = async() => {
      const data = await taxPortalClientApi.mDM_GetSelectedLegalEntities(props.selectedRowsData[0].hqSiteId, props.selectedRowsData[0].mdmClientId);
      setArrData({pending:false, data:data});
    }
    fetchData()
  },[])
  useEffect(() => {
    if (arrData.data != null) {
      const mappedData = arrData.data.map((item: any) => ({
        mdmClientId: props.selectedRowsData[0]?.mdmClientId,
        clientId: item.clientId,
        clientName: item.clientName,
        hqClientName: "",
        hqSiteId: props.selectedRowsData[0]?.hqSiteId,
        suppressFolderCreation: item.suppressFolderCreation,
        suppressDropdown: item.suppressDropdown,
        suppressProjectStatus: item.suppressProjectStatus,
        userName: user?.profile.preferred_username as string,
      }));
      props.setDataPermission(mappedData);
    }
  }, [arrData]);


  const cellRender = (e: any) => {
    return (
      <CheckBox
        defaultValue={
          arrData.data.length > 0 && e.data.suppressFolderCreation !== undefined && e.data.suppressFolderCreation !== null
            ? e.data.suppressFolderCreation
            : false
        }
        onValueChanged={(a) => {
          const modifiedData = props.dataPermission.map((b: any) => {
            if (b.clientId === e.data.clientId) {
              b.suppressFolderCreation = a.value;
            }
            return b;
          });
          props.setDataPermission(modifiedData);
          localStorage.setItem("data", JSON.stringify(modifiedData));
        }}
        disabled = {!props.handleEdit}
      />
    );
  };
  const cellRender2 = (e: any) => {
    return (
      <CheckBox
        defaultValue={
          arrData.data.length > 0 && e.data.suppressDropdown !== undefined && e.data.suppressDropdown !== null
            ? e.data.suppressDropdown
            : false
        }
        onValueChanged={(a) => {
          const modifiedData = props.dataPermission.map((b: any) => {
            if (b.clientId === e.data.clientId) {
              b.suppressDropdown = a.value;
            }
            return b;
          });
          props.setDataPermission(modifiedData);
          localStorage.setItem("data", JSON.stringify(modifiedData));
        }}
        disabled = {!props.handleEdit}
      />
    );
  };
  const cellRender3 = (e: any) => {
    return (
      <CheckBox
        defaultValue={
          arrData.data.length > 0 && e.data.suppressProjectStatus !== undefined && e.data.suppressProjectStatus !== null
            ? e.data.suppressProjectStatus
            : false
        }
        onValueChanged={(a) => {
          const modifiedData = props.dataPermission.map((b: any) => {
            if (b.clientId === e.data.clientId) {
              b.suppressProjectStatus = a.value;
            }
            return b;
          });
          props.setDataPermission(modifiedData);
          localStorage.setItem("data", JSON.stringify(modifiedData));
        }}
        disabled = {!props.handleEdit}
      />
    );
  };
  return (
    <div>
      <div style={{ margin: "0 0 16px 0" }}>{props.selectedRowsData?.name}</div>
      <DataGrid
        {...dataGridConfig}
        showBorders={true}
        height={250}
        dataSource={arrData.data}
        noDataText={arrData.pending ? "Loading..." : "No Data"}
      >
        <LoadPanel enabled={false} />
        <Paging defaultPageSize={5}/>
        <Column
          width={130}
          alignment="left"
          dataField="clientId"
          caption={"Client Id"}
        ></Column>
        <Column
          alignment="left"
          minWidth={180}
          dataField="clientName"
          caption={"Client Name"}
        ></Column>
        <Column
          alignment="center"
          width={230}
          type={"checkbox"}
          cellRender={cellRender}
          caption={"Suppress in Folder creation in Files"}
        ></Column>
        <Column
          alignment="center"
          width={220}
          cellRender={cellRender2}
          caption={"Suppress in Information Request"}
        ></Column>
        <Column
          alignment="center"
          width={180}
          cellRender={cellRender3}
          caption={"Suppress in Project Status"}
        ></Column>
      </DataGrid>
    </div>
  );
};

export default LegalEntitesEditPermission;
