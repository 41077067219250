import React, { useContext, useEffect, useState, useCallback } from "react";
import { DataGrid, Column } from "devextreme-react/data-grid";
import { MasterDetailTemplateData } from "devextreme/ui/data_grid";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import {
  GetAllRolesDTO,
  GetAllSitesInfoDTO,
  GetSiteInfoDTO,
  ServiceBusEnum,
  ServiceMessage,
  SiteTrackRecordDTO,
  SyncTypeEnum,
} from "../../../api-client-nswag/taxportal-client";
import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Button, Link } from "@mui/material";
import { dataGridConfig } from "../../../constants";
import { SnackbarMessage, useSnackbar } from "../../../hooks";
import { OidcIdentityContext } from "@rsmus/react-auth";
import { getExpandedRowData, getRoleData } from "../CreateSite&SetUp";
import { isEmpty, isNull } from "lodash";
import { convertUTCTimeToLocal } from "../../../util/Utils";
import { getAppName } from "../../../cui/commonComponents/AppNameToggle";
import Roles from "../../Roles/Roles";

interface dataTypes {
  pending: boolean;
  data: SiteTrackRecordDTO[] | undefined;
  fullfilled: boolean;
}

export const statusStateForCS = atom({
  key: "statusState1",
  default: { siteId: null, status: "Active" },
});
interface statusType {
  siteId: number | null | any;
  status: string;
}
interface roleType {
  name?: string | undefined;
  RoleId?: string | undefined;
}

export const SiteProcessDataForCS = (props: MasterDetailTemplateData) => {
  const [data, setData] = useState<dataTypes>({
    pending: false,
    data: [],
    fullfilled: false,
  });
  const { user, logout } = useContext(OidcIdentityContext);
  const siteId = useRecoilValue(getExpandedRowData);
  const { showSnackbar } = useSnackbar();
  const [status, setStatus] = useRecoilState<statusType>(statusStateForCS);
  const appName = useRecoilValue(getAppName);
  const roles = useRecoilValue(getRoleData);

  const processToInclude = [
    "SYNC_MDM",
    "RUN_SITES_API",
    "POPULATE_MEMBERFIRM_DROPDOWN",
    "SITE_CREATION",
    "CS_ISHEET_DEFAULT_DATA",
    "SYNC_CEM",
    "CLIENT_LEVEL_ROLE_ASSIGN",
    "INTERNAL_USER_ASSIGNMENT_CS",
    "CREATE_EXTERNAL_USER_CS",
    "CHECK_EXTERNAL_USER_STATUS",
    "MEMBER_FIRM_CS"
  ];

  const subProcessToInclude = ["L2Support", "CLIENT_USER", "RSM_ENGAGEMENT_TEAM", "FUNCTIONAL_LEAD", "PROJECT_LEAD","MEMBER_FIRM_CS"];

  const CREATE_EXTERNAL_USER_CS = "CREATE_EXTERNAL_USER_CS";
  const CLIENT_LEVEL_ROLE_ASSIGN = "CLIENT_LEVEL_ROLE_ASSIGN";
  const INTERNAL_USER_ASSIGNMENT_CS = "INTERNAL_USER_ASSIGNMENT_CS";
  const CS_PROJECT_LEAD = "CS_PROJECT_LEAD";
  const CS_FUNCTIONAL_LEAD = "CS_FUNCTIONAL_LEAD";
  const CS_ENGAGEMENT_TEAM = "CS_ENGAGEMENT_TEAM";
  const CS_POWER_USER_DELEGATED ="CS_POWER_USER_DELEGATED"
  const CS_L2Support = "CS_L2Support"
  const CS_ISHEET_DEFAULT_DATA = "CS_ISHEET_DEFAULT_DATA";
  const MEMBER_FIRM_CS = "MEMBER_FIRM_CS"
  const PROCESS_TYPES = {
    CS_ISHEET_DEFAULT_DATA,
    CREATE_EXTERNAL_USER_CS,
    CLIENT_LEVEL_ROLE_ASSIGN,
    INTERNAL_USER_ASSIGNMENT_CS,
    CHECK_EXTERNAL_USER_STATUS: "CHECK_EXTERNAL_USER_STATUS",
    MEMBER_FIRM_CS
  };
  const SUBTYPES = {
    CS_PROJECT_LEAD,
    CS_FUNCTIONAL_LEAD,
    CS_ENGAGEMENT_TEAM,
    CS_L2Support,
    CS_POWER_USER_DELEGATED,
    TRANSITION_PLAN: "TRANSITION_PLAN",
    DELIVERY_PLAN: "DELIVERY_PLAN",
    PROJECT_SETUP_TASK_TEMPLATES: "PROJECT_SETUP_TASK_TEMPLATES",
    ANALYTIC_INSIGHT_SETUP: "ANALYTIC_INSIGHT_SETUP",
    IDM_AND_CEM: "IDM_AND_CEM",
    ASSIGN_USER_TO_SITE: "ASSIGN_USER_TO_SITE",
  };

  const createProcess = (type: string, subType?: string): SiteTrackRecordDTO => ({
    type,
    status: "",
    message: "",
    createdBy: "",
    created: undefined,
    lastModified: undefined,
    subType,
  });

  const ensureProcessExists = (processes: SiteTrackRecordDTO[], type: string, subTypes: string[] = []) => {
    if (!processes.some((e) => e.type === type)) {
      if (subTypes.length > 0) {
        subTypes.forEach((subType) => processes.push(createProcess(type, subType)));
      } else {
        processes.push(createProcess(type));
      }
    }
  };

  const ensureRequiredProcesses = (processes: SiteTrackRecordDTO[]) => {
    ensureProcessExists(processes, PROCESS_TYPES.CS_ISHEET_DEFAULT_DATA, [
      SUBTYPES.TRANSITION_PLAN,
      SUBTYPES.DELIVERY_PLAN,
      SUBTYPES.PROJECT_SETUP_TASK_TEMPLATES,
      SUBTYPES.ANALYTIC_INSIGHT_SETUP
    ]);

    ensureProcessExists(processes, PROCESS_TYPES.CREATE_EXTERNAL_USER_CS, [
      SUBTYPES.IDM_AND_CEM,
      SUBTYPES.ASSIGN_USER_TO_SITE,
    ]);
    ensureProcessExists(processes, PROCESS_TYPES.MEMBER_FIRM_CS, [
      SUBTYPES.IDM_AND_CEM,
      SUBTYPES.ASSIGN_USER_TO_SITE,
    ]);

    ensureProcessExists(processes, PROCESS_TYPES.CLIENT_LEVEL_ROLE_ASSIGN, [
      SUBTYPES.CS_PROJECT_LEAD,
      SUBTYPES.CS_FUNCTIONAL_LEAD,
      SUBTYPES.CS_ENGAGEMENT_TEAM,
      SUBTYPES.CS_L2Support,
      SUBTYPES.CS_POWER_USER_DELEGATED
    ]);

    ensureProcessExists(processes, PROCESS_TYPES.CHECK_EXTERNAL_USER_STATUS);
  };

  const filterProcesses = (processes: SiteTrackRecordDTO[]) => {
    return processes.filter((item: any) => {
      const includeProcess = processToInclude.includes(item.type);
      const clientLevelRoleCondition =
        item.type === CLIENT_LEVEL_ROLE_ASSIGN && (item.subType === null || subProcessToInclude.includes(item.subType));
      const createExternalUserCondition = item.type === CREATE_EXTERNAL_USER_CS && item.subType === null;
      const excludeTessCondition = (
        item.type === CLIENT_LEVEL_ROLE_ASSIGN &&
        (item.subType === "TESS" || item.subType === "POWER_USER_DELEGATED")
      );
      
      return includeProcess && !clientLevelRoleCondition && !createExternalUserCondition && !excludeTessCondition;
    });
  };

  const processData = async () => {
    try {
      if (siteId !== null) {
        setData({ pending: true, data: [], fullfilled: false });
        const processDataStatus = await taxPortalClientApi.siteSetUp_SiteSetUpStatus(siteId);
        const processes = processDataStatus?.data || [];
        ensureRequiredProcesses(processes);
        const filteredProcesses = filterProcesses(processes);

        [SUBTYPES.CS_PROJECT_LEAD, SUBTYPES.CS_FUNCTIONAL_LEAD, SUBTYPES.CS_ENGAGEMENT_TEAM, SUBTYPES.CS_L2Support, SUBTYPES.CS_POWER_USER_DELEGATED].forEach((subType) => {
          if (!filteredProcesses.some((e) => e.type === CLIENT_LEVEL_ROLE_ASSIGN && e.subType === subType)) {
            filteredProcesses.push(createProcess(CLIENT_LEVEL_ROLE_ASSIGN, subType));
          }
        });

        [SUBTYPES.ASSIGN_USER_TO_SITE].forEach((subType) => {
          if (!filteredProcesses.some((e) => e.type === CREATE_EXTERNAL_USER_CS && e.subType === subType)) {
            filteredProcesses.push(createProcess(CREATE_EXTERNAL_USER_CS, subType));
          }
        });

        setData({ pending: false, data: filteredProcesses, fullfilled: true });
      }
    } catch (error) {
      console.error("Error processing data:", error);
      setData({ pending: false, data: [], fullfilled: false });
    }
  };

  useEffect(() => {
    if (siteId !== null) {
      processData();
    }
  }, []);

  const cellFunction1 = (celldata: any) => {
    const type = celldata.data.type;
    const subType = celldata.data.subType;

    const typeMapping: { [key: string]: string } = {
      SYNC_MDM: "Sync MDM",
      RUN_SITES_API: "Run Sites API",
      POPULATE_MEMBERFIRM_DROPDOWN: "Populate Member Firm Organization",
      SITE_CREATION: "Create Site from template",
      SYNC_CEM: "Sync CEM",
      CS_ISHEET_DEFAULT_DATA: `Populate Isheet - ${subType}`,
      CREATE_EXTERNAL_USER_CS: `Assign External User - ${subType}`,
      CLIENT_LEVEL_ROLE_ASSIGN: `Assign Internal User - ${subType}`,
      INTERNAL_USER_ASSIGNMENT_CS: "Assign Internal- Internal User Request Form",
      CHECK_EXTERNAL_USER_STATUS: "External User - Update Status",
      MEMBER_FIRM_CS :  `Assign Member firm user - ${subType}`
    };

    return typeMapping[type] || "Unknown Type";
  };

  const customOrderCS = [
    "SITE_CREATION",
    "SYNC_MDM",
    "RUN_SITES_API",
    "POPULATE_MEMBERFIRM_DROPDOWN",
    "CS_ISHEET_DEFAULT_DATA",
    "SYNC_CEM",
    "CLIENT_LEVEL_ROLE_ASSIGN",
    "INTERNAL_USER_ASSIGNMENT_CS",
    "CREATE_EXTERNAL_USER_CS",
    "CHECK_EXTERNAL_USER_STATUS",
    "MEMBER_FIRM_CS"
  ];
  const sortedDataSourceforCS = data?.data?.sort((a, b) => {
    return customOrderCS.indexOf(a.type!) - customOrderCS.indexOf(b.type!);
  });

  const canRun = () => {
    return sortedDataSourceforCS?.find((e) => {
      if (e.type === "SYNC_MDM" && e.status === "SUCCESS") return true;
      else return false;
    });
  };

  const isSyncMdmInProgressOrError = () => {
    return sortedDataSourceforCS?.some((e) => {
      return e.type === "SYNC_MDM" && (e.status === "ERROR" || e.status === "INPROGRESS" || e.status === null);
    });
  };

  const retryButton = (celldata: any) => {
    const type = celldata.data.type;
    const subType = celldata.data.subType;
    const status = celldata.data.status;
    const roleNames = celldata.data.subType;
    const roleId = roles?.find((role: GetAllRolesDTO) => {
      return role?.name?.toLowerCase().replace(/[^a-z0-9]/g, "") === roleNames?.toLowerCase().replace(/[^a-z0-9]/g, "");
    })?.roleId;
    const showRetry = () => {
      return type === "SITE_CREATION" && (status === null || status === "INPROGRESS" || status === "ERROR");
    };
    const showRun = () =>
      type === "SYNC_MDM" ||
      type === "RUN_SITES_API" ||
      type === "POPULATE_MEMBERFIRM_DROPDOWN" ||
      type === "CS_ISHEET_DEFAULT_DATA" ||
      type === "SYNC_CEM" ||
      type === "CREATE_EXTERNAL_USER_CS" ||
      type === "CHECK_EXTERNAL_USER_STATUS" ||
      type === "INTERNAL_USER_ASSIGNMENT_CS" ||
      type === "CLIENT_LEVEL_ROLE_ASSIGN" ||
      type === "MEMBER_FIRM_CS"

    const retryProcess = async (celldata: any) => {
      const syncType = (() => {
        if (type === "SYNC_MDM") return ServiceBusEnum.SYNC_MDM;
        if (type === "SYNC_MDM") return ServiceBusEnum.SYNC_CEM;
        if (type === "CS_ISHEET_DEFAULT_DATA") return ServiceBusEnum.CS_ISHEET_DEFAULT_DATA;
        if (type === "CLIENT_LEVEL_ROLE_ASSIGN" && subType === "CS_ENGAGEMENT_TEAM") {
          return ServiceBusEnum.PROVIDE_APP_ACCESS;
        }
        if (type === "CLIENT_LEVEL_ROLE_ASSIGN" && subType === "CS_PROJECT_LEAD") {
          return ServiceBusEnum.PROVIDE_APP_ACCESS;
        }
        if (type === "CLIENT_LEVEL_ROLE_ASSIGN" && subType === "CS_FUNCTIONAL_LEAD") {
          return ServiceBusEnum.PROVIDE_APP_ACCESS;
        }
        if (type === "CLIENT_LEVEL_ROLE_ASSIGN" && subType === "CS_L2Support") {
          return ServiceBusEnum.PROVIDE_APP_ACCESS;
        }
        if (type === "CLIENT_LEVEL_ROLE_ASSIGN" && subType === "CS_POWER_USER_DELEGATED") {
          return ServiceBusEnum.PROVIDE_APP_ACCESS;
        }
        if (type === "CREATE_EXTERNAL_USER_CS" && subType === "IDM_AND_CEM") {
          return ServiceBusEnum.CREATE_EXTERNAL_USER_CS;
        }
        if (type === "CREATE_EXTERNAL_USER_CS" && subType === "ASSIGN_USER_TO_SITE") {
          return ServiceBusEnum.CREATE_EXTERNAL_USER_CS;
        }
        if (type === "MEMBER_FIRM_CS" && subType === "IDM_AND_CEM") {
          return ServiceBusEnum.MEMBER_FIRM_CS;
        }
        if (type === "MEMBER_FIRM_CS" && subType === "ASSIGN_USER_TO_SITE") {
          return ServiceBusEnum.MEMBER_FIRM_CS;
        }
        if (type === "CHECK_EXTERNAL_USER_STATUS")
          return ServiceBusEnum.CHECK_EXTERNAL_USER_STATUS;
        if (type === "CS_ISHEET_DEFAULT_DATA")
          return ServiceBusEnum.CS_ISHEET_DEFAULT_DATA;
        if (type ===  INTERNAL_USER_ASSIGNMENT_CS)
          return ServiceBusEnum.INTERNAL_USER_ASSIGNMENT_CS
        if(type ==="RUN_SITES_API")
          return ServiceBusEnum.RUN_SITES_API
        if(type ==="POPULATE_MEMBERFIRM_DROPDOWN")
          return ServiceBusEnum.POPULATE_MEMBERFIRM_DROPDOWN
        return null;
      })();

      const assignmentType = (() => {
        if (subType === "ASSIGN_USER_TO_SITE") {
          return "Assign User to site";
        }
        if (subType === "IDM_AND_CEM") return "IDM and CEM";
        if (type === "CHECK_EXTERNAL_USER_STATUS") return "Check Status";
      })();

      const isheetType = (() => {
        if (type === "MEMBER_FIRM_CS" && subType === "IDM_AND_CEM") return "Member Firm User Request Form";
        if (type === "MEMBER_FIRM_CS" && subType === "ASSIGN_USER_TO_SITE") return "Member Firm User Request Form";
        if (type === "CREATE_EXTERNAL_USER_CS" && subType === "IDM_AND_CEM") return "Client User Requests";
        if (type === "CREATE_EXTERNAL_USER_CS" && subType === "ASSIGN_USER_TO_SITE") return "Client User Requests";
        if (type === "CHECK_EXTERNAL_USER_STATUS") return "";
        if (type === "CS_ISHEET_DEFAULT_DATA" && subType === "ANALYTIC_INSIGHT_SETUP") return "Analytic Insight Setup";
        if (type === "CS_ISHEET_DEFAULT_DATA" && subType === "PROJECT_SETUP_TASK_TEMPLATES")
          return "Project Setup – Task Templates";
        if (type === "CS_ISHEET_DEFAULT_DATA" && subType === "DELIVERY_PLAN") return "Delivery Plan";
        if (type === "CS_ISHEET_DEFAULT_DATA" && subType === "TRANSITION_PLAN") return "Transition Plan";
      })();

      const roleType = (() => {
        if (subType === "CS_PROJECT_LEAD") {
          return "CS - Project Lead";
        }
        if (subType === "CS_FUNCTIONAL_LEAD") {
          return "CS - Functional Lead";
        }
        if (subType === "CS_ENGAGEMENT_TEAM") {
          return "CS - Engagement Team";
        }
        if(subType === "CS_L2Support"){
          return "CS - L2 Support"
        }
        if(subType === "CS_POWER_USER_DELEGATED"){
          return "CS - Power User_Delegated"
        }
      })();

      if (syncType) {
        const data: ServiceMessage = {
          siteId: props?.data?.data?.hqSiteId,
          userName: user?.profile.preferred_username,
          eventType: syncType,
          externalUserType: assignmentType,
          roleName: roleType,
          roleId: roleId,
          isheetType: isheetType,
          mdmClientId: props?.data?.data?.mdmClientId,
          clientName: props?.data?.data?.name,
          appName: appName,
        };
        try {
          if (syncType === ServiceBusEnum.PROVIDE_APP_ACCESS) {
            showSnackbar({ message: SnackbarMessage.PROVIDE_APP_ACCESS });
            await taxPortalClientApi.taxPortal_ProvideAppAccess([data]);
         }
          if(syncType === ServiceBusEnum.RUN_SITES_API){
            await taxPortalClientApi.siteSetUp_SitesAPI(data)
          }
         else {
           await taxPortalClientApi.taxPortal_InsertTopic([data]);
         }
          if (syncType === ServiceBusEnum.SYNC_MDM) {
            showSnackbar({ message: SnackbarMessage.SYNC_MDM });
          }
          if (syncType === ServiceBusEnum.POPULATE_MEMBERFIRM_DROPDOWN) {
            showSnackbar({ message: SnackbarMessage.POPULATE_MEMBER_FIRM_ORG });
          }
          if (syncType === ServiceBusEnum.SYNC_CEM) {
            showSnackbar({ message: SnackbarMessage.SYNC_MDM });
          }
          if (syncType === ServiceBusEnum.CS_ISHEET_DEFAULT_DATA && subType === "ANALYTIC_INSIGHT_SETUP") {
            showSnackbar({ message: SnackbarMessage.ANALYTIC_INSIGHT_SETUP });
          }
          if (syncType === ServiceBusEnum.CS_ISHEET_DEFAULT_DATA && subType === "TRANSITION_PLAN") {
            showSnackbar({ message: SnackbarMessage.TRANSITION_PLAN });
          }
          if (syncType === ServiceBusEnum.CS_ISHEET_DEFAULT_DATA && subType === "DELIVERY_PLAN") {
            showSnackbar({ message: SnackbarMessage.DELIVERY_PLAN });
          }
          if (syncType === ServiceBusEnum.CS_ISHEET_DEFAULT_DATA && subType === "PROJECT_SETUP_TASK_TEMPLATES") {
            showSnackbar({
              message: SnackbarMessage.PROJECT_SETUP_TASK_TEMPLATES,
            });
          }
          if (syncType === ServiceBusEnum.CREATE_EXTERNAL_USER_CS && subType === "IDM_AND_CEM") {
            showSnackbar({
              message: SnackbarMessage.IDM_AND_CEM,
            });
          }
          if (syncType === ServiceBusEnum.CREATE_EXTERNAL_USER_CS && subType === "ASSIGN_USER_TO_SITE") {
            showSnackbar({
              message: SnackbarMessage.ASSIGN_USER_TO_SITE,
            });
          }
          if (syncType === ServiceBusEnum.CHECK_EXTERNAL_USER_STATUS) {
            showSnackbar({
              message: SnackbarMessage.CHECK_EXTERNAL_USER_STATUS,
            });
          }
          if(syncType === ServiceBusEnum.INTERNAL_USER_ASSIGNMENT_CS) {
            showSnackbar({ message: SnackbarMessage.PROVIDE_APP_ACCESS});
          }
          if(syncType === ServiceBusEnum.MEMBER_FIRM_CS) {
            showSnackbar({ message: SnackbarMessage.MEMBER_FIRM_CS});
          }
          if(syncType === ServiceBusEnum.RUN_SITES_API) {
            showSnackbar({ message: SnackbarMessage.CS_Run_Sites_API});
          }
        } catch (error) {
          showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
        }
      }
    };

    const retryForSynMDM = async (celldata :any) =>{
      const data: ServiceMessage = {
        siteId: props?.data?.data?.hqSiteId,
        userName: user?.profile.preferred_username,
        eventType: ServiceBusEnum.SYNC_MDM,
        mdmClientId: props?.data?.data?.mdmClientId,
        clientName : props?.data?.data?.name,
        appName: appName,
      };
      await taxPortalClientApi.taxPortal_InsertTopic([data]);
    }
    return (
      <div>
        {showRetry() ? (
          <Link component="button" variant="body2" onClick={retryProcess}>
            Run
          </Link>
        ) : showRun() ? (
          <Link
            component="button"
            variant="body2"
            style={{
              color: isSyncMdmInProgressOrError() && type !== "SYNC_MDM" ? "grey" : "009cde",
              pointerEvents: isSyncMdmInProgressOrError() && type !== "SYNC_MDM" ? "none" : "auto",
              opacity: isSyncMdmInProgressOrError() && type !== "SYNC_MDM" ? 0.5 : 1,
            }}
            onClick={canRun() && showRun() ? retryProcess : retryForSynMDM} 
          >
            Run
          </Link>
        ) : null}
      </div>
    );
  };

  const checkStatusAndUpdate = (celldata: any) => {
    if (!isNull(celldata) && !isEmpty(celldata)) {
      const hasError = celldata.some((e: any) => e.status === "ERROR");
      const hasSuccess = celldata.some((e: any) => e.status === "SUCCESS");
      const hasEmptyStatus = celldata.some((e: any) => e.status === null);

      if (hasEmptyStatus) {
        return "INCOMPLETE";
      }
      if (!hasError) {
        return "SUCCESS";
      }
      if (hasSuccess) {
        return "PARTIAL SUCCESS";
      }
      if (celldata.every((e: any) => e.status === "ERROR")) {
        return "FAILED";
      }
    }
    return "";
  };

  const createdDateTime = (e: any) => {
    if (!isNull(e.data.status) && !isEmpty(e.data.status)) {
      if (e.data.created === null) {
        return null;
      } else {
        return (
          <div style={{ display: "flex" }}>
            <div>
              <div>{convertUTCTimeToLocal(e.data.created)}</div>
            </div>
          </div>
        );
      }
    } else return null;
  };
  const modifiedDateTime = (e: any) => {
    if (!isNull(e.data.status) && !isEmpty(e.data.status)) {
      if (e.data.created === null) {
        return null;
      } else {
        return (
          <div style={{ display: "flex" }}>
            <div>
              <div>{convertUTCTimeToLocal(e.data.lastModified)}</div>
            </div>
          </div>
        );
      }
    } else return null;
  };

  useEffect(() => {
    if (sortedDataSourceforCS != null && siteId !== null) {
      const parentStatus = checkStatusAndUpdate(sortedDataSourceforCS);
      setStatus({ siteId: siteId, status: parentStatus });
    }
  }, [sortedDataSourceforCS]);
  return (
    <div>
      <DataGrid
        {...dataGridConfig}
        dataSource={sortedDataSourceforCS}
        showBorders={true}
        allowColumnResizing={true}
        showRowLines={true}
        columnAutoWidth={true}
        noDataText={data.pending === true && data.fullfilled === false ? "Loading..." : "No data"}
      >
        <Column dataField="type" width={310} caption="process" cellRender={cellFunction1} allowSorting={true} />
        <Column dataField="status" caption="Status" />
        <Column width={290} dataField="message" caption="Message" />
        <Column dataField="createdBy" caption="Initiated by" />
        <Column cellRender={createdDateTime} dataField="created" caption="Initiated date and time" />
        <Column cellRender={modifiedDateTime} dataField="lastModified" caption="Completed date and time" />
        <Column width={100} dataField="Run Process" cellRender={retryButton} />
      </DataGrid>
    </div>
  );
};
